<template>
  <Holder :content="list">
    <List :list-data="list" #="{ item }">
      <div class="df-middle df-between mb-12 p-16 item" :class="{ act: !used, 'c-tip': used }">
        <Money :v="item.price" as="div" class="ml-6 f-xl f-medium" />
        <div>
          <t v-if="item.status === 1" path="coupon_7"></t>
          <t v-else-if="item.status === 2" path="coupon_9"></t>
          <t v-else-if="item.status === 3" path="coupon_8"></t>
          <!-- 'Expired date', 'Used', 'Expired' -->
          <Time class="ml-4" :time="item.endDate" format="MM-dd-yyyy" />
        </div>
      </div>
    </List>
  </Holder>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Time from '@/components/Time.vue'
import Money from '@/components.global/Money'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'

export default defineComponent({
  name: 'TheItem',
  components: { Holder, List, Money, Time },
  props: {
    list: {
      type: Array,
    },
    used: {
      type: Boolean,
    },
  },
})

</script>

<style scoped lang="scss">
.item {
  background: var(--color-light);
  overflow: hidden;
  position: relative;

  &.act {
    background: #E6EDFE;
    color: var(--color-black);
  }
}

.item::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  right: -7px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--color-theme);
}

</style>
