<template>
  <PageWithHeader class="px-16">
    <template #title>
      <t path="coupon_2">Coupon</t>
    </template>
    <t as="p" path="coupon_3" class="c-title mb-12 f-md mt-20">Rules of use</t>
    <div class="f-md mb-24 c-title" style="line-height: 18px;">
      <t as="div" path="coupon_4" multiline #="{td}" custom>
        <p v-for="(p, index) in td" :key="index">{{p}}</p>
      </t>
    </div>
    <t #="{td, t}" custom>
      <Tab :tabs="[t('coupon_5') || 'Available', t('coupon_6') || 'Used & expired']"
           @switch="getActive" :active="sel" />
    </t>
    <div class="mt-12">
      <TheItem :list="list" :used="sel === 1" />
    </div>
  </PageWithHeader>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import TheItem from '@/pages/coupons/components/TheItem.vue'
import { readVoucherList, VoucherSchema, VoucherStatus } from '@/pages/coupons/coupons.api'

export default defineComponent({
  name: 'Coupons',
  components: { TheItem, Tab, PageWithHeader },
  setup () {
    const sel = ref(0)
    const list = ref<Array<VoucherSchema> | null>(null)

    const getCoupons = (index: number) => {
      const data = {
        type: 1,
        page: 1,
        pageCount: 1000,
        status: index === 0 ? VoucherStatus.USABLE : `${VoucherStatus.USED},${VoucherStatus.EXPIRED}`,
      }
      readVoucherList(data)
        .then(resp => list.value = resp as Array<VoucherSchema>)
    }

    getCoupons(sel.value)

    const getActive = (index: number) => {
      sel.value = index
      getCoupons(index)
    }

    return { sel, getActive, list }
  },
})
</script>
